<template>
  <v-container>
    <DoctorForm   v-if="tabNumber===1" :item="itemRow" @tabChange="loadTab" @setItemRow="itemRow = $event" class="ma-0 pa-0"/>
    <DoctorDtForm v-if="tabNumber===2" :item="itemRow" @tabChange="loadTab" @setItemRow="itemRow = $event" class="ma-0 pa-0"/>
  </v-container>
</template>

<script>
import DoctorForm from '@/components/DoctorForm.vue'
import DoctorDtForm from '@/components/DoctorDtForm.vue'
  export default {
    name: 'Doctor',
    props: {
      //
    },
    components: {
      DoctorForm, DoctorDtForm
    },
    data: () => ({
      //
      tabNumber: 1,
      itemRow: [],
      //
    }),
    methods: {
      initialize: function () {
        //
      },
      loadTab: async function (val) {
        await this.scrollToTop();
        this.tabNumber = await  val;
      },
      processData: function () {
        //
      }
    },
    created () {
      //
    },
    mounted () {
      //
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      //
    }
  }
</script>
