<template>
  <v-container>
    <v-toolbar flat class="ma-0 pa-0"
      v-show="loggedIn === 1">
      <v-spacer></v-spacer>
      <v-col v-show="showFind" cols="4" sm="4" md="4">
        <v-text-field
          class="mt-2 pt-2"
          v-show="showFind"
          v-model=searchValue
          ref="text_find"
          label="Buscando por.."
          prepend-inner-icon="mdi-magnify"
          append-outer-icon="mdi-close"
          @click:append-outer="cancelFind()"
          v-on:keyup="keymonitor">
        </v-text-field>
      </v-col>
      <v-btn
        v-show="showBtnPrint"
        @click.stop="btnPrint()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-printer
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >imprimir
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnNew"
        @click.stop="btnNew()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-file-plus
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >nuevo
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnEdit"
        @click.stop="btnEdit()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-pencil
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >editar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnCancel"
        @click.stop="btnCancel()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-close
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >Cancelar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnSave"
        @click.stop="btnSave()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-check
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >salvar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnFind"
        @click.stop="btnFind()"
        class="ma-1 pa-1"
        :plain="!this.showFind ? true:false"
        :color="this.showFind ? 'primary':''"
        >
        <v-icon large color="blue darken-4"
          >mdi-magnify
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >
          {{ showFind ? 'Buscando' : 'Buscar' }}
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnClose"
        @click.stop="btnClose()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-chevron-left
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >cerrar
        </span>
      </v-btn>
    </v-toolbar>
    <v-card class="overflow-hidden ma-0 pa-0" flat>
      <!-- v-toolbar
        color="white" 
        flat dense
        >
        <v-toolbar-title>{{title}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar -->
      <v-card-text class="ma-0 pa-0">
        <v-container>
          <v-form>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field id="text_name" ref="text_name" v-on:keyup="keymonitor" :disabled="!isEditing"
                  v-model="editedItem.name" label="Nombre"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
              <v-select
                ref="text_color"
                id="text_color"
                v-model="editedItem.color"
                :items="col"
                :disabled="!isEditing"
                label="Color"
                dense chips
                >
                <template v-slot:selection="{ item }">
                  <v-chip text-color="white" :color="item.color" small
                  >{{ item.text }}</v-chip>
                </template>
                <template v-slot:item="{  item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters >
                        <span>{{ item.descr }}</span>
                        <v-spacer></v-spacer>
                        <v-chip text-color="white" :color="item.color" small
                        >{{ item.text }}</v-chip>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select id="text_active" ref="text_active" 
                  v-model="editedItem.active"
                  label="Es Activo?"
                  v-on:keyup="keymonitor"
                  :items="selectItemYN"
                  :disabled="!isEditing"
                  item-text="item"
                  item-value="val"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
  export default {
    name: 'DoctorDtForm',
    props: [
      'item'
    ],
    components: {
      //
    },
    data: () => ({
      //
      searchValue: null,
      showFind: false,
      //
      showBtnSave: false,
      showBtnCancel: false,
      showBtnEdit: true,
      showBtnFind: false,
      showBtnPrint: true,
      showBtnNew: false,
      showBtnClose: true,
      //
      isEditing: null,
      editedItem: [],
      selectItemYN: [
        { item: 'Si', val: '1' },
        { item: 'No', val: '0' }
      ],
      rules: {
        required: value => !!value || 'Campo Requerido.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      //
      selected_colOLD: [],
      col: [
        {
          value: 'primary',
          text: 'Azul',
          descr: 'Azul',
          color: "primary",
        },
        {
          value: 'orange',
          text: 'Naranja',
          descr: 'Naranja',
          color: "orange",
        },
        {
          value: 'red',
          text: 'Rojo',
          descr: 'Rojo',
          color: "red",
        },
        {
          value: 'green',
          text: 'Verde',
          descr: 'Verde',
          color: "green",
        },
        {
          value: 'teal',
          text: 'Verde Azul',
          descr: 'Verde Azul',
          color: "teal",
        },
        {
          value: 'purple',
          text: 'Morado',
          descr: 'Morado',
          color: "purple",
        },
        {
          value: 'cyan',
          text: 'Cian',
          descr: 'Cian',
          color: "cyan",
        },
        {
          value: 'pink',
          text: 'Rosa',
          descr: 'Rosa',
          color: "pink",
        },
        {
          value: 'deep-orange',
          text: 'Naranja Intenso',
          descr: 'Naranja Intenso',
          color: "deep-orange",
        },
      ],
      //
    }),
    methods: {
      initialize: async function () {
        this.editedItem = await this.copyObjects(this.item);
        if(await this.editedItem.id===0){
          this.showBtnEdit = await false;
          this.showBtnPrint = await false;
          this.showBtnSave = await true;
          await this.editFocus('text_name');
        }
      },
      btnSave: async function () {
        if(await this.validationFields()){
          const token = await JSON.parse(sessionStorage.getItem('localToken'));
          if (await token) {
            let apiRoute = await 'doctorcreate';
            let formData = await this.editedItem;
            formData['employee_id'] = await this.userData.id;
            const returnData = await true;
            let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
            // console.log(dataResponse);
            if (dataResponse) {
              let message = await dataResponse.message;
              let type = await 'info';
              await this.$store.dispatch('loadMessage', { msg: message, type: type });
              this.showBtnSave = await false;
              this.showBtnCancel = await false;
              this.showBtnPrint = await true;
              this.showBtnEdit = await true;
              this.isEditing = await false;
              if(await dataResponse.data.id !== await this.editedItem.id){
                this.editedItem.id = await dataResponse.data.id;
              }
              await this.$emit('setItemRow', this.copyObjects(this.editedItem));
            }
          }
        }
        //
      },
      validationFields: function () {
        let isValid = true;

        if(isValid===true){
          isValid = this.isEmpty(
            this.editedItem.name,
            'El Campo Nombre no puede ser blanco',
            'text_name'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.editedItem.color,
            'El Campo Color no puede ser blanco',
            'text_color'
          );
        }

        if(isValid===true){
          isValid = this.isEmpty(
            this.editedItem.active,
            'El Campo Activo no puede ser blanco',
            'select_active'
          );
        }
        return isValid;
      },
      btnPrint: async function () {
        //
      },
      btnClose: async function () {
        this.$emit('setItemRow', []);
        this.$emit('tabChange', 1);
      },
      btnNew: async function () {
        //
      },
      btnEdit: async function () {
        // *** this.title = this.title.substring(0,this.titleLength);
        // *** this.title = this.title + ' (EDITANDO REGISTRO)';
        this.showBtnPrint = false;
        this.showBtnEdit = false;
        this.showBtnCancel = true;
        this.showBtnSave = true;
        this.editFocus('text_name');
      },
      btnCancel: async function () {
        this.showBtnPrint = true;
        this.showBtnEdit = true;
        this.isEditing = false;
        this.showBtnCancel = false;
        this.showBtnSave = false;
        // *** this.title = this.title.substring(0,this.titleLength);
        this.editedItem = this.copyObjects(this.item);
      },
      btnFind: async function () {
        this.showFind = await !this.showFind;
        await setTimeout(() => {
          this.$refs.text_find.focus();
        }, 500);
      },
      cancelFind: async function () {
        this.searchValue = await null;
        this.showFind = await false;
      },
      keymonitor: async function (event) {
        if (await event.key === 'Enter') {
          await this.$refs.text_active.blur();
          await this.btnSave();
        }
      },
      processData: function () {
        //
      }
    },
    created () {
      this.initialize();
    },
    mounted () {
      //
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      //
    }
  }
</script>
